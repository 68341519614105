<template>
	<div class="w-100 landing-page">
		<!-- Hero -->
		<hero
			id="hero"
			:background="heroData.background"
			:content="heroData.content"
			:ctas="heroData.ctas"
			:content-classes="'align-items-center'"
			:highlight-color="highlightColor.primary" />
		<!-- Intro header -->
		<lazy
			id="overview-section"
			min-height="200">
			<small-header
				:title="introHeaderData.title"
				:subtitle="introHeaderData.subtitle" />
		</lazy>
		<!-- Intro -->
		<lazy
			v-for="(section, index) in introData.sections"
			:key="`about-${index}`"
			:min-height="section.height">
			<media-section
				:media="section.media"
				:highlight-color="section.color"
				:title="section.title"
				:content="section.content"
				:reversed="section.reversed"
				:container-style="`background-color: #FFFFFF; padding-bottom: 48px; height: ${section.height}px;`"
				:cta="section.cta" />
		</lazy>
		<!-- Benefits icons -->
		<lazy min-height="300">
			<icons-section
				:highlight-color="highlightColor.secondary"
				:text-color="highlightColor.text"
				:include-addendum="iconsData.includeAddendum"
				:background-style="iconsData.background"
				:icons="iconsData.icons"
				:max-cols="iconsData.cols"
				:title="iconsData.title"
				:smaller-texts="true"
				:disclaimer="iconsData.disclaimer" />
		</lazy>
		<!-- Video header -->
		<lazy min-height="150">
			<small-header
				:title="videoHeaderData.title" />
		</lazy>
		<!-- Video section -->
		<lazy
			id="video-section"
			:min-height="videoData.height">
			<media-section
				:media="videoData.media"
				:highlight-color="highlightColor.primary"
				:content="videoData.content"
				:reversed="videoData.reversed"
				:container-style="`background-color: #FFFFFF; height: ${videoData.height}px`" />
		</lazy>
		<!-- Buy section -->
		<lazy
			id="buy-section"
			min-height="300">
			<double-buy-section
				:highlight-color="highlightColor.primary"
				:subtitle="buySectionData.subtitle"
				:background="buySectionData.background"
				:products="buySectionData.products" />
		</lazy>
		<!-- Contact section -->
		<lazy min-height="300">
			<contact-section
				:highlight-color="highlightColor.secondary"
				:loading="loadingReplicatedData"
				:email="email"
				:image="contactData.image"
				:title="contactData.title"
				:content="contactData.content"
				:cta="contactData.cta"
				:container-style="`${contactData.background} ${['xs', 'sm'].includes(windowWidth) ? '' : 'height: 400px !important;'}`"
				@mail="openMailer" />
		</lazy>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { DOUBLE_PRODUCT_PAGES, RESTRICTED_BY_COUNTRY } from '@/settings/LandingPages2';
import { SPECIAL_VALUES } from '@/settings/Replicated';
import { S3_PATH } from '@/settings/Images';
import { LandingPages } from '@/translations';
import ReplicatedSite from '@/util/ReplicatedSite';
import DoubleBuySection from '../DoubleBuySection';
import ContactSection from '../ContactSection';
import Hero from '../Hero';
import SmallHeader from '../SmallHeader';
import IconsSection from '../IconsSection';
import MediaSection from '../MediaSection';
import { applyAttrsToHtmlString } from '@/util/HtmlHelper';
import Lazy from '@/components/Lazy';

export default {
	name: 'DoubleProductLandingPage',
	messages: [LandingPages],
	components: {
		MediaSection,
		Hero,
		IconsSection,
		ContactSection,
		SmallHeader,
		DoubleBuySection,
		Lazy,
	},
	mixins: [WindowSizes],
	props: {
		pageName: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			replicated: new ReplicatedSite(),
		};
	},
	computed: {
		contentStyles() {
			return {
				'ul, h5, h6': {
					style: { color: this.highlightColor.primary },
				},
				ul: {
					style: { paddingLeft: '1.5rem' },
				},
				'li>span': {
					class: 'font-weight-bold',
				},
				'h3, .h4, p, li>span': {
					style: { color: this.highlightColor.text },
				},
				b: {
					class: 'font-weight-bolder',
				},
				'h3, h4': {
					class: 'font-weight-bolder p-0 m-0 header-letter-spacing',
				},
				'.h4': {
					class: 'pt-1',
					style: { 'line-height': '1.3' },
				},
				'h5, h6': {
					class: 'font-weight-bolder p-0 m-0',
				},
			};
		},
		highlightColor() {
			return DOUBLE_PRODUCT_PAGES[this.pageName].colors;
		},
		heroData() {
			const {
				background,
				ctas,
				content,
			} = DOUBLE_PRODUCT_PAGES[this.pageName].hero;

			return {
				background: this.requireAsset(background),
				ctas,
				content: content.map((item) => ({ ...item, text: applyAttrsToHtmlString(this.translate(item.text), this.contentStyles, { keepClasses: true }) })),
			};
		},
		introHeaderData() {
			const {
				title,
				subtitle,
			} = DOUBLE_PRODUCT_PAGES[this.pageName].introHeader;

			return {
				title: applyAttrsToHtmlString(this.translate(title), this.contentStyles, { keepClasses: true }),
				subtitle: this.translate(subtitle),
			};
		},
		introData() {
			const {
				sections,
			} = DOUBLE_PRODUCT_PAGES[this.pageName].media;

			const result = sections.map((section, index) => {
				const {
					title,
					content,
					cta,
					reversed,
					video,
					color,
					media,
				} = section;

				let height = '500px';
				if (index === 0) {
					// eslint-disable-next-line no-nested-ternary
					height = ['xs', 'sm'].includes(this.windowWidth) ? 790 : (['md'].includes(this.windowWidth) ? 900 : 500);
				} else if (index === 1) {
					// eslint-disable-next-line no-nested-ternary
					height = ['xs', 'sm'].includes(this.windowWidth) ? 690 : (['md'].includes(this.windowWidth) ? 800 : 500);
				}

				const sectionData = {
					title: applyAttrsToHtmlString(this.translate(title), this.contentStyles, { keepClasses: true }),
					content: applyAttrsToHtmlString(this.translate(content), this.contentStyles, { keepClasses: true }),
					video,
					color,
					reversed: reversed || false,
					media: this.transformMedia(media),
					height,
				};

				if (cta) {
					sectionData.cta = this.transformCta(cta);
				}

				return sectionData;
			});

			return {
				sections: result,
			};
		},
		iconsData() {
			const {
				icons,
				iconCols,
				background,
				includeAddendum,
				title,
				disclaimer,
			} = DOUBLE_PRODUCT_PAGES[this.pageName].icons;

			const result = icons.map((icon) => ({
				text: this.translate(icon.text),
				file: this.requireAsset(icon.file),
			}));

			const data = {
				icons: result,
				cols: iconCols,
				includeAddendum,
				title: this.translate(title),
				disclaimer: this.translate(disclaimer),
			};

			if (background) {
				data.background = [
					`background-image: url('${this.requireAsset(background)}');`,
					'background-size: cover;',
					'background-position: center;',
					'background-repeat: none;',
				].join(' ');
			}

			return data;
		},
		videoHeaderData() {
			const {
				title,
			} = DOUBLE_PRODUCT_PAGES[this.pageName].videoHeader;

			return {
				title: applyAttrsToHtmlString(this.translate(title), this.contentStyles, { keepClasses: true }),
			};
		},
		videoData() {
			const {
				content,
				reversed,
				media,
			} = DOUBLE_PRODUCT_PAGES[this.pageName].video;

			// eslint-disable-next-line no-nested-ternary
			const height = ['xs', 'sm'].includes(this.windowWidth) ? 690 : (['md'].includes(this.windowWidth) ? 730 : 480);

			return {
				reversed,
				content: applyAttrsToHtmlString(this.translate(content), this.contentStyles, { keepClasses: true }),
				media: this.transformMedia(media),
				height,
			};
		},
		buySectionData() {
			const {
				background,
				products,
				subtitle,
			} = DOUBLE_PRODUCT_PAGES[this.pageName].buy;

			return {
				background: this.requireAsset(background),
				products: products.map((product) => ({
					searchName: product.searchName,
					color: product.color,
					title: this.translate(product.title),
				})),
				subtitle: applyAttrsToHtmlString(this.translate(subtitle), this.contentStyles, { keepClasses: true }),
			};
		},
		contactData() {
			const {
				image,
				title,
				content,
				cta,
				background,
			} = DOUBLE_PRODUCT_PAGES[this.pageName].contact;

			const ctaData = this.transformCta(cta);

			const data = {
				image: this.requireAsset(image),
				title: { ...title, text: this.translate(title.text) },
				content: { ...content, text: this.translate(content.text) },
				cta: ctaData,
			};

			if (background) {
				data.background = [
					`background-image: url('${this.requireAsset(background)}');`,
					`background-size: ${['xs', 'sm'].includes(this.windowWidth) ? '500%' : 'cover'};`,
					'background-position: center;',
					'background-repeat: none;',
				].join(' ');
			}

			return data;
		},
		loadingReplicatedData() {
			return !!this.replicated.data.loading;
		},
		email() {
			try {
				return this.replicated.data.response.data.response.email[0].value;
			} catch (error) {
				return '';
			}
		},
	},
	created() {
		const restricted = RESTRICTED_BY_COUNTRY[this.country] ?? RESTRICTED_BY_COUNTRY.default ?? [];
		if (restricted.includes(this.pageName)) {
			this.$router.replace({ name: 'Store' });
		}

		this.replicated.clear();

		const replicatedUsername = this.$replicated.replicatedId();
		if (replicatedUsername) {
			this.replicated.getReplicatedSiteNav(replicatedUsername).catch(() => {
				this.$replicated.siteOff();
				this.$replicated.siteReplicatedSponsorOff();
				this.$replicated.siteReplicatedPageOff();
			});
		}
	},
	methods: {
		requireAsset(directory) {
			if (!directory) {
				return '';
			}
			// Allow importing assets with variable directories
			/* eslint-disable global-require */
			/* eslint-disable import/no-dynamic-require */
			// require(`@/assets/images/themes/${process.env.VUE_APP_THEME}/landing_pages/${this.pageName}/${directory}`);
			return `${S3_PATH}/assets/landing-pages/${process.env.VUE_APP_THEME}/${this.pageName}/${directory}`;
		},
		transformMedia(media) {
			return {
				...media,
				image: this.requireAsset(media.image),
			};
		},
		transformCta(cta) {
			const result = {
				text: this.translate(cta.text),
			};

			if (cta.url) {
				result.url = cta.url;
			}

			if (cta.showModal) {
				result.showModal = cta.showModal;
			}

			if (cta.scrollTo) {
				result.scrollTo = cta.scrollTo;
			}

			if (cta.videoUrl) {
				result.videoUrl = cta.videoUrl;
			}

			if (cta.target) {
				result.target = cta.target;
			}
			return result;
		},
		makeHighlightStyle(propertyName) {
			if (this.highlightColor.primary) {
				return `${propertyName}: ${this.highlightColor.primary} !important;`;
			}
			return '';
		},
		openMailer() {
			if (this.email) {
				location.href = SPECIAL_VALUES.email(this.email); // eslint-disable-line no-restricted-globals
			}
		},
	},
};
</script>
<style scoped lang="scss">
html, body {
	scroll-behavior: smooth;
}

.landing-page::v-deep {
	.section-header {
		font-size: 3rem;
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 800 !important;
	}

	.font-weight-bolder {
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 800 !important;
	}

	.header-letter-spacing {
		letter-spacing: 0.1rem !important;
	}

	.text-shadow {
		text-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px;
	}

	.bran-color {
		color: #DF703D !important;
	}

	.zlem-color {
		color: #66B2E8 !important;
	}

	.plos-color {
		color: #E73540 !important;
	}

	.uuth-color {
		color: #9FC642 !important;
	}
}
</style>
