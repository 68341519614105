<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="position-relative">
		<div
			class="position-absolute h-100 bg-white w-100"
			style="top: 0; left: 0">
			<div>
				<div
					class="row"
					style="height: 35vh;">
					<div
						:style="backgroundStyles"
						class="col-12" />
				</div>
				<div
					class="row"
					style="height: 100%; max-height: 35vh !important;">
					<div class="col-12 bg-white" />
				</div>
			</div>
		</div>
		<div
			class="position-relative">
			<section
				:class="['xs', 'sm', 'md'].includes(windowWidth) ? '': 'p-5'"
				class="w-100 row no-gutters text-dark">
				<div class="col d-flex justify-content-center align-items-center">
					<div
						:class="['xs', 'sm', 'md'].includes(windowWidth) ? '': 'px-5'"
						class="container-xl mw-100">
						<h1
							style="letter-spacing: 5px"
							:class="{
								'pt-4 mt-1': ['xs', 'sm'].includes(windowWidth),
								'pt-3 mt-5': windowWidth === 'md',
							}"
							class="text-center text-white font-weight-bolder mb-0 text-uppercase text-shadow">
							{{ translate('buy_now') }}
						</h1>
						<div
							v-if="!!subtitle"
							class="text-center mt-3 text-shadow">
							<div
								v-html="subtitle" />
						</div>
						<b-row
							class="product-cards"
							align-h="center">
							<b-col
								v-if="typeof products[0] !== 'undefined'"
								:class="['xs', 'sm'].includes(windowWidth) ? '' : 'px-5'"
								cols="12"
								md="6">
								<product-card
									modal-id="product-card-1"
									:highlight-color="products[0].color"
									:title="products[0].title"
									:product="products[0].searchName" />
							</b-col>
							<b-col
								v-if="typeof products[1] !== 'undefined'"
								:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'px-5'"
								cols="12"
								md="6">
								<product-card
									modal-id="product-card-2"
									:highlight-color="products[1].color"
									:title="products[1].title"
									:product="products[1].searchName" />
							</b-col>
						</b-row>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import { Store } from '@/translations';
import Color from '@/views/LandingPages2/mixins/Color';
import WindowSizes from '@/mixins/WindowSizes';
import ProductCard from './ProductCard';

export default {
	name: 'DoubleBuySection',
	messages: [Store],
	components: {
		ProductCard,
	},
	mixins: [Color, WindowSizes],
	props: {
		background: {
			type: String,
			required: true,
		},
		subtitle: {
			type: String,
			default: '',
		},
		products: {
			type: Array,
			required: true,
			validate: (value) => value.length === 2,
		},
	},
	computed: {
		backgroundStyles() {
			const styles = [
				`background-image: url('${this.background}');`,
				'background-size: cover;',
				'background-position: center;',
				'background-repeat: none;',
				this.backgroundHeight,
			];

			return styles.join(' ');
		},
	},
};
</script>
<style scoped>
.product-cards::v-deep {
	.config-btn, .add-to-cart-button {
		text-transform: uppercase;
	}
}
</style>
