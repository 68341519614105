<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="py-5"
		style="background-color: #FFFFFF; !important">
		<b-container>
			<div
				class="text-center"
				v-html="title" />
			<div
				class="text-center"
				v-html="subtitle" />
			<div
				class="text-center"
				v-html="cta" />
		</b-container>
	</div>
</template>

<script>
export default {
	name: 'SmallHeader',
	props: {
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		cta: {
			type: String,
			default: '',
		},
	},
};
</script>
